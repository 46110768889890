
import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator'
import { Dictionary } from 'vue-router/types/router'

import { Dyestuff, UsedDyestuff1 } from '@/types'

export interface Form {
  amount: number | null
  dyestuff: number | null
}

export const initForm = () => ({
  amount: null,
  dyestuff: null,
})

const isUsedDyestuff1 = (x: any): x is UsedDyestuff1 => {
  return Boolean(x && x?.dyestuff && x?.name)
}

@Component({})
export default class ReservationOperationHistoryDyestuffForm1 extends Vue {
  @Model('change', { type: Object, default: null }) readonly value!:
    | UsedDyestuff1
    | Form
    | null
  @Prop({ type: Object, default: () => ({}) }) readonly errors!: Dictionary<
    string[]
  >
  @Prop({ required: true }) readonly dyestuffs!: Dyestuff[]
  form = initForm()

  get items() {
    const id = this.value?.dyestuff
    const current = this.dyestuffs.find((x) => x.id === id)
    const val = this.value
    const rval: Dyestuff[] = []

    if (!current && isUsedDyestuff1(val)) {
      rval.push({
        id: val.dyestuff,
        name: val.name,
        brand: val.brand,
        isActive: val.isActive,
      })
    }
    return rval.concat(this.dyestuffs.filter((x) => x.isActive))
  }

  @Watch('value', { immediate: true, deep: true })
  onValueChange(value: Form | null) {
    if (!value) this.form = initForm()
    else if (value !== this.form) Object.assign(this.form, value)
  }

  getDyestuffText({ name }: Dyestuff) {
    return name
  }

  onChange() {
    this.$emit('change', this.form)
  }
}
