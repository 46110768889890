var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"ticker red",style:({
    position: 'absolute',
    top: 0,
    width: '2px',
    height: _vm.height,
    left: _vm.left,
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }