
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { ReserveAailability } from '@/types'

@Component
export default class TimeListDialogCard extends Vue {
  @Prop({ type: String, required: true }) readonly date!: string
  @Prop({ type: String, required: true }) readonly shopId!: string
  @Prop({ type: String, required: true }) readonly menuId!: string

  loading = false
  items: ReserveAailability[] = []

  get times() {
    return this.items.filter(x => x.available).map(x => x.time)
  }

  @Watch('menuId', { immediate: true })
  onMenuChanged() {
    this.fetchData()
  }

  async fetchData() {
    this.loading = true
    try {
      this.items = await this.$api
        .timelines({ shop: this.shopId, date: this.date })
        .availabilities({ menu: this.menuId })
    } catch (err) {
      console.error(err)
    }
    this.loading = false
  }

  goTimeline(time?: string) {
    let route = this.$route

    this.$router.push({
      path: route.path,
      query: Object.assign({}, route.query, { date: this.date }),
    })

    this.$emit('push', { date: this.date, time: time, menu: this.menuId })
  }
}
