
import { Overflow } from '@/types'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class OverFlowCount extends Vue {
  @Prop({ type: Object, required: true }) readonly timeToOverflow!: Record<
    string,
    Overflow
  >
  @Prop({ type: String, required: true }) readonly time!: string

  get overflow() {
    return this.timeToOverflow[this.time] || null
  }

  get count() {
    return this.overflow?.overflowCount
  }

  get visible() {
    const of = this.overflow
    return of && of.overflowCount > 0
  }
}
