
import moment from 'moment'

import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { Menu } from '@/types'
import TimeList from './TimeListDialogCard.vue'

@Component({
  components: {
    TimeList,
  },
})
export default class TimeListDialog extends Vue {
  @Prop({ type: String, required: true }) readonly shop!: string
  @Prop({ type: String, required: true }) readonly date!: string
  @Prop({ type: Array, required: true }) readonly menus!: Menu[]

  startDate = ''
  dialog = false
  dateMenu = false
  menu: null | string = null

  get dates() {
    let rval = []
    let d = moment(this.startDate)
    for (let i = 0; i < 8; ++i) {
      rval.push(d.format('YYYY-MM-DD'))
      d.add(1, 'days')
    }
    return rval
  }

  get minDate() {
    return moment().format('YYYY-MM-DD')
  }

  get disablePrev() {
    return this.startDate <= this.minDate
  }

  @Watch('date', { immediate: true })
  onDateChanged(date: string) {
    if (date < this.minDate) this.startDate = this.minDate
    else this.startDate = date
  }

  @Watch('menus', { immediate: true })
  onMenusChanged(menus: Menu[]) {
    if (menus.length > 0) this.menu = this.menus[0].id
    else this.menu = null
  }

  goToday() {
    this.startDate = moment().format('YYYY-MM-DD')
  }
  goNextWeek() {
    this.startDate = moment(this.startDate)
      .add(1, 'week')
      .format('YYYY-MM-DD')
  }
  goPrevWeek() {
    this.startDate = moment(this.startDate)
      .add(-1, 'week')
      .format('YYYY-MM-DD')
  }
  onPush(val: { date: string; time?: string }) {
    this.dialog = false
    if (val.time) {
      this.$emit('open-create-dialog', val)
    }
  }
}
