
import moment from 'moment'
import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator'
import { Dictionary } from 'vue-router/types/router'

import DateSelect from '../DateSelect.vue'
import TimeSelect2 from './TimeSelect2.vue'
import {
  State,
  Menu,
  SimpleStaff,
  Reservation,
  ReserveAailability,
  ScalpCare,
  TreatmentMenu,
} from '@/types'
import StateSelect from './StateSelect.vue'
import MethodSeldct from './MethodSelect.vue'

export const initForm = () => ({
  date: '',
  time: '',
  shop: '',
  state: State.New,
  menu: null as null | string,
  treatment: null as null | string,
  scalpCare: null as null | number,
  addtionalDyestuff: false,
  reservedBy: null as null | number,
  attendedStaff: null as null | string,
  staff: null as null | string,
  shampooStaff: null as null | string,
  note: '',

  phoneNumberRequired: true,
  nocharge: true,
  recommendStaffDisabled: false,
})

export type Form = ReturnType<typeof initForm>

@Component({
  components: {
    StateSelect,
    MethodSeldct,
    DateSelect,
    TimeSelect2,
  },
})
export default class ReservationBasicForm extends Vue {
  @Model('change', { type: Object, default: null }) readonly value!: Form | null
  @Prop({ type: Object, default: () => ({}) }) readonly errors!: Dictionary<
    string[]
  >
  @Prop({ type: Object, default: null }) readonly reserve!: Reservation | null
  @Prop({ required: true }) readonly menus!: Menu[]
  @Prop({ required: true }) readonly treatments!: TreatmentMenu[]
  @Prop({ type: Array, required: true }) readonly scalpCares!: ScalpCare[]

  @Prop({ required: true }) readonly staffs!: SimpleStaff[]
  @Prop({ required: true }) readonly allStaffs!: SimpleStaff[]

  @Prop({ type: String, required: true }) readonly minTime!: string
  @Prop({ type: String, required: true }) readonly maxTime!: string
  @Prop({ type: Number, required: true }) readonly unitMinutes!: number
  @Prop({ type: Array, required: true })
  readonly defaultAvailabilities!: ReserveAailability[]
  @Prop({ type: String, default: '' }) readonly prevNote!: string

  visibleAllTimeOptions = false
  loadingTimeOptions = false
  timeOptions: null | string[] = null
  form = initForm()
  color = null as null | number

  get colors() {
    const rval: any[] = []
    const ids: any = {}
    // @ts-ignore
    this.menus
      // @ts-ignore

      .map(m => m.color)
      .forEach(color => {
        if (color) {
          if (!(color.id in ids)) {
            ids[color.id] = true
            rval.push(color)
          }
        }
      })
    return rval
  }
  get filteredMenus() {
    const cid = this.color
    if (!cid) return this.menus
    return this.menus.filter(menu => menu.color?.id === cid)
  }

  @Watch('form.time')
  onTimeChanged() {
    // this.checkTimeOptions()
  }
  @Watch('selectedMenu')
  async onSelectedMenuChanged() {
    await this.fetchTimeOptions()
    // this.checkTimeOptions()
  }

  @Watch('form.date')
  async onDateChanged() {
    await this.fetchTimeOptions()
    // this.checkTimeOptions()
  }

  @Watch('form.menu')
  onMenuChanged(mid: number) {
    // @ts-ignore
    const m = this.menus.find(m => m.id === mid)
    if (m) {
      this.color = m?.color.id || null
    }
  }

  // @Watch('reserve', { immediate: true })
  // onReserveChanged(reserve: null | Reservation) {
  //   // NOTE: reserveがnullの場合は新規作成の可能性があるので初期化しない
  //   if (reserve) {
  //     this.form = initForm()
  //     let form = this.form
  //     Object.keys(form).forEach(k => {
  //       // @ts-ignore
  //       form[k] = reserve[k]
  //     })
  //     this.onChange()
  //   }
  // }

  @Watch('value', { deep: true, immediate: true })
  onValueChange(value: Form | null) {
    if (value && value !== this.form) Object.assign(this.form, value)
  }

  get requestColorText() {
    return this.reserve?.requestColorText
  }
  get hotpepperNo() {
    return this.reserve?.hotpepperNo
  }
  get hotpepperPoint() {
    return this.reserve?.hotpepperPoint
  }

  get selectedMenu() {
    let id = this.form.menu
    if (!id) return undefined
    return this.menus.find(x => x.id === id)
  }

  // get filteredMenuOptions() {
  //   let menuId = this.form.menu
  //   let menu = this.menus.find(x => x.id === menuId)
  //   if (!menu) return []
  //   let opts = menu.options
  //   return this.menuOptions
  //     .filter(x => opts.includes(x.id))
  //     .filter(x => x.name !== '薬剤追加')
  // }

  created() {
    this.fetchTimeOptions()
  }

  checkTimeOptions() {
    // NOTE: 本来入らない場所に予約が入ってる場合は、時間制限解除
    let time = this.form.time
    let opts = this.timeOptions
    if (!time) {
      this.visibleAllTimeOptions = false
    } else if (opts && !opts.includes(time)) {
      // TODO: 制限期間内なら代わりにform.timeをnullにする
      this.visibleAllTimeOptions = true
    } else {
      this.visibleAllTimeOptions = false
    }
  }

  async fetchTimeOptions() {
    let shop = this.form.shop
    let date = this.form.date
    if (!shop || !date) {
      this.timeOptions = null
      // console.log('? shop || date', shop, date)
      return
    }

    try {
      this.loadingTimeOptions = true
      let availabilities = await this.$api
        .timelines({ shop, date })
        .availabilities({
          menu: this.selectedMenu?.id,
          reserve: this.reserve?.id,
        })
      let time = this.form.time

      let menu = this.selectedMenu
      let items = null
      if (!menu)
        // NOTE: menuが未選択の場合はshortの時間を返す
        items = availabilities.filter(x => x.availableShort || x.time === time)
      else
        items = availabilities.filter(
          x => x.available // || x.time === time
        )
      this.timeOptions = items.map(x => moment(x.at).format('HH:mm'))
    } catch (err) {
      console.error(err)
      this.$toast.error('予約可能時間の取得に失敗しました。')
      this.timeOptions = null
    }
    this.loadingTimeOptions = false
  }

  onChange() {
    let form = this.form
    // NOTE: clear可能なフィールドはundefinedが入ってる場合があるのでnullにする
    if (!form.treatment) form.treatment = null
    if (!form.attendedStaff) form.attendedStaff = null
    if (!form.staff) form.staff = null
    if (!form.shampooStaff) form.shampooStaff = null
    this.$emit('change', form)
  }

  clear() {
    this.color = null
  }
}
