<script>
import moment from 'moment'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'TicketFormTable2',
  components: {
    InfiniteLoading,
  },
  props: {
    customer: {
      type: String,
      required: true,
    },
    usedTickets: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    items: [],
    loading: false,
    ts: String(new Date().getTime()),
    next: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: '購入日',
          value: 'created',
        },
        {
          text: 'チケットグループ',
          value: 'groupName',
        },
        {
          text: '名称',
          value: 'name',
        },
        {
          text: '有効期限',
          value: 'expiryAt',
        },
        {
          text: '残りチケット数',
          value: 'restQuantity',
        },
        {
          text: 'ステータス',
          value: 'status',
        },
      ]
    },
  },
  methods: {
    reload() {
      this.items = []
      this.next = null
      this.ts = String(new Date().getTime())
    },
    async fetchData($state) {
      try {
        this.loading = true
        let { results, next } = await (this.next
          ? this.$api.http.get(this.next)
          : this.$api
              .purchasedTickets()
              .list({ customer: this.customer, usable: false }))
        this.items = this.items.concat(results)
        // this.$emit('items', this.items)
        this.next = next
        if (this.items.length) $state.loaded()
        if (!this.next) $state.complete()
      } catch (err) {
        console.error(err)
        $state.error()
      }
      this.loading = false
    },
    status(item) {
      if (item.restQuantity <= 0) return '使用済'
      if (moment(item.expiryAt).isBefore(new Date())) return '有効期限切れ'
    },
  },
}
</script>

<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    :items-per-page="items.length"
    height="400px"
    hide-default-footer
    fixed-header
  >
    <template #item.created="{ value }">
      {{ value | date("'YY MM/DD") }}
    </template>
    <template #item.expiryAt="{ value }">
      {{ value | date("'YY MM/DD") }}
    </template>
    <template #item.status="{item}">
      <v-chip v-if="usedTickets.includes(item.id)" outlined>
        使用中
      </v-chip>

      <template v-if="!item.isActive">
        無効化（{{ item.updated | datetime }}）
      </template>
      {{ status(item) }}
    </template>
    <template #body.append>
      <tr>
        <td :colspan="headers.length">
          <infinite-loading :identifier="ts" @infinite="fetchData" />
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
