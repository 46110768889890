
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class RejectCounter extends Vue {
  @Prop({ type: String, default: null }) readonly shop!: null | string
  @Prop({ type: String, required: true }) readonly date!: string

  count = 0

  @Watch('date', { immediate: true })
  onDateChanged() {
    this.fetchData()
  }
  @Watch('shop', { immediate: true })
  onShopChanged() {
    this.fetchData()
  }

  async fetchData() {
    if (!this.date || !this.shop) {
      this.count = 0
      return
    }
    this.count = await this.$api
      .shops(this.shop)
      .rejectCount(this.date)
      .get()
  }
  async inc() {
    if (!this.shop) return
    this.count = await this.$api
      .shops(this.shop)
      .rejectCount(this.date)
      .inc()
  }
  async dec() {
    if (!this.shop) return
    this.count = await this.$api
      .shops(this.shop)
      .rejectCount(this.date)
      .dec()
  }
}
