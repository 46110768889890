var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" チケット ")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-btn',{on:{"click":function($event){_vm.purchaseDialog = true}}},[_vm._v(" 購入 ")])],1)],1),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.purchaseQuantityDialog),callback:function ($$v) {_vm.purchaseQuantityDialog=$$v},expression:"purchaseQuantityDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 購入数量 ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.purchaseTicket && _vm.purchaseTicket.name)+" "),_c('v-text-field',{attrs:{"min":"1","label":"数量","type":"numbeer","disabled":""},model:{value:(_vm.purchaseQuantity),callback:function ($$v) {_vm.purchaseQuantity=_vm._n($$v)},expression:"purchaseQuantity"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.purchaseQuantityDialog = false}}},[_vm._v(" キャンセル ")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.adding},on:{"click":_vm.purchase}},[_vm._v(" 購入 ")])],1)],1)],1),_c('v-dialog',{model:{value:(_vm.purchaseDialog),callback:function ($$v) {_vm.purchaseDialog=$$v},expression:"purchaseDialog"}},[_c('v-card',{attrs:{"loading":_vm.purchaseDialogLoading}},[_c('v-card-title',[_vm._v("チケット購入")]),_c('v-card-text',[_c('v-row',_vm._l((_vm.tickets),function(ticket){return _c('v-col',{key:ticket.id},[_c('v-btn',{attrs:{"outlined":"","x-large":""},on:{"click":function($event){return _vm.openQuantity(ticket)}}},[_vm._v(" "+_vm._s(ticket.name)+" "+_vm._s(Number(ticket.price).toLocaleString())+"円 ")])],1)}),1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.purchaseDialog = false}}},[_vm._v("閉じる")])],1)],1)],1),(_vm.usedTickets.length > 0)?[_c('v-subheader',[_vm._v(" 使用中 ")]),_c('v-data-table',{attrs:{"items":_vm.usedTickets,"headers":[
        { text: 'チケットID', value: 'ticket' },
        { text: 'チケットグループ', value: 'groupName' },
        { text: '名称', value: 'name' },
        { text: '残り枚数', value: 'restQuantity' },
        { text: '使用枚数', value: 'usedQuantity' },
        { text: '', value: 'actions' },
      ],"items-per-page":_vm.usedTickets.length,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.ticket",fn:function({value}){return [_vm._v(" "+_vm._s(value || '削除済')+" ")]}},{key:"item.usedQuantity",fn:function({value}){return [_c('b',{staticClass:"info pa-2 white--text"},[_vm._v(_vm._s(value))])]}},{key:"item.actions",fn:function({item}){return [_c('v-btn',{attrs:{"color":"error","disabled":_vm.disableDelete},on:{"click":function($event){return _vm.delUsedTicket(item)}}},[_vm._v("取り消し")])]}}],null,false,832162510)})]:_vm._e(),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("使用可能")]),_c('v-tab',[_vm._v("使用済")])],1),_c('v-window',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-window-item',[_c('TicketFormTable',{ref:"tbl",attrs:{"customer":_vm.customer,"reserve-id":_vm.reserveId,"reserve-date":_vm.reserveDate,"used-tickets":_vm.usedTickets.map(x => x.ticket)},on:{"use":_vm.fetchUsedTickets}})],1),_c('v-window-item',[_c('TicketFormTable2',{attrs:{"customer":_vm.customer,"reserve-id":_vm.reserveId,"used-tickets":_vm.usedTickets.map(x => x.ticket)}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }