
import { Component, Prop, Vue, Model } from 'vue-property-decorator'
import { ReservedBy } from '@/types/reservation'

@Component
export default class ReservatioonMethoodSelect extends Vue {
  @Model('change', { default: null }) readonly value!: ReservedBy
  @Prop({ default: '予約方法' }) readonly label!: string
  get items() {
    return [
      { text: '直接来店', value: ReservedBy.Direct },
      { text: '次回予約', value: ReservedBy.Repeat },
      { text: '電話予約', value: ReservedBy.Phone },
      { text: 'ホットペッパー', value: ReservedBy.Hotpepper },
      // { text: 'コールセンター', value: ReservedBy.CallCenter },
      { text: 'Line@', value: ReservedBy.Line },
      { text: 'オープン前ハント', value: ReservedBy.PreOpen },

      { text: '次回予約（お客さま）', value: ReservedBy.ShopTablet },
      { text: '店頭端末', value: ReservedBy.InShop2 },

      { text: 'fufuアプリ', value: ReservedBy.App },
      { text: 'その他', value: ReservedBy.Other },
    ]
  }
}
