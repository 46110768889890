<script>
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'UsedTicketCard',
  components: {
    InfiniteLoading,
  },
  props: {
    ticket: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    items: [],
    next: null,
  }),
  methods: {
    async fetchData($state) {
      if (!this.ticket) {
        $state.complete()
        return
      }
      try {
        this.loading = true
        let { results, next } = await (this.next
          ? this.$api.http.get(this.next)
          : this.$api.usedTickets().list({ ticket: this.ticket.id }))
        this.items = this.items.concat(results)
        // this.$emit('items', this.items)
        this.next = next
        if (this.items.length) $state.loaded()
        if (!this.next) $state.complete()
      } catch (err) {
        console.error(err)
        $state.error()
      }
      this.loading = false
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-title>
      チケット使用履歴
    </v-card-title>
    <v-card-text>
      購入日: {{ ticket.created | date("'YY MM/DD") }}
      <br />
      アイテム: {{ ticket.name }}

      <v-data-table
        :items="items"
        :headers="[
          { text: '使用日', value: 'usedDate' },
          { text: '使用枚数', value: 'usedQuantity' },
        ]"
        :items-per-page="items.length"
        hide-default-footer
      >
        <template #body.append>
          <tr>
            <td :colspan="2">
              <infinite-loading
                :identifier="ticket && ticket.id"
                @infinite="fetchData"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <slot />
  </v-card>
</template>
