<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>
        タイムライン 店舗選択
      </v-toolbar-title>
    </v-toolbar>
    <v-list dense>
      <v-list-item
        v-for="shop in items"
        :key="shop.id"
        :to="{ name: 'timeline-chart', params: { shopId: shop.id } }"
      >
        <v-list-item-title>
          {{ shop.name }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
    <infinite-loading @infinite="infiniteHandler" />
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import store from '@/store'

export default {
  name: 'TimelineShopSelection',
  components: {
    InfiniteLoading,
  },
  beforeRouteEnter(to, from, next) {
    let user = store.getters.user
    if (
      to.query.autoDetectShop &&
      user &&
      user.shop &&
      user.shop.shopType === 1
    ) {
      next({
        name: 'timeline-chart',
        params: { shopId: user.shop.id },
      })
    } else {
      next()
    }
  },
  data: () => ({
    items: [],
    next: null,
  }),
  methods: {
    async infiniteHandler($state) {
      try {
        let { results, next } = await (this.next
          ? this.$api.http.get(this.next)
          : this.$api.shops().list({ ordering: 'code' }))
        this.items = this.items.concat(results)
        this.next = next
        if (results.length > 0) $state.loaded()
        if (!next) $state.complete()
      } catch (err) {
        console.error(err)
        $state.error()
      }
    },
  },
}
</script>
