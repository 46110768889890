<template>
  <v-layout align-center>
    <v-select
      v-model="hour"
      :items="hourOptions"
      v-bind="$attrs"
      :label="labelHour"
      @change="onChange"
    />
    <v-select
      v-model="minute"
      :items="minuteOptions"
      :label="labelMinute"
      v-bind="$attrs"
      @change="onChange"
    >
      <slot slot="prepend" name="prependM">
        <span>:</span>
      </slot>
    </v-select>
    <v-btn v-if="clearable && value" icon @click="onClear">
      <v-icon>clear</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
// TODO: 分の下限, 上限を超えないかruleを記述する
const parseTime = s => {
  if (!s) return [null, null]
  let [h, m] = String(s).split(':')
  h = parseInt(h)
  m = parseInt(m)
  if (!isNaN(h) && !isNaN(m)) {
    return [h, m]
  }
  return [null, null]
}

export default {
  name: 'TimeSelect',
  props: {
    value: {
      type: String,
      default: null,
    },
    labelHour: {
      type: String,
      default: '時',
    },
    labelMinute: {
      type: String,
      default: '分',
    },
    hourStep: {
      type: [String, Number],
      default: 1,
    },
    minuteStep: {
      type: [String, Number],
      default: 10,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    hour: null,
    minute: null,
  }),
  computed: {
    hourOptions() {
      let rval = []
      let [minH] = parseTime(this.min)
      let [maxH] = parseTime(this.max)
      console.log(minH, maxH)
      minH = minH === null ? 0 : minH
      maxH = maxH === null ? 23 : maxH
      let step = Number(this.hourStep) || 1
      // NOTE: シフトの休憩時間の終わりにmaxHを選択するためにn <= maxHとして応急処置
      for (let n = minH; n <= maxH; n += step)
        rval.push({
          text: n < 10 ? `0${n}` : String(n),
          value: n,
        })
      return rval
    },
    minuteOptions() {
      let step = Number(this.minuteStep) || 1
      let rval = []
      for (let m = 0; m < 60; m += step) {
        rval.push({
          text: m < 10 ? `0${m}` : String(m),
          value: m,
        })
      }
      return rval
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          let [h, m] = parseTime(value)
          this.hour = h
          this.minute = m
        } else {
          this.hour = null
          this.minute = null
        }
      },
    },
  },
  methods: {
    onChange() {
      let h = this.hour
      let m = this.minute
      if (typeof h === 'number' && typeof m === 'number') {
        let sh = String(h)
        let sm = String(m)
        if (h < 10) sh = '0' + sh
        if (m < 10) sm = '0' + sm
        this.$emit('change', `${sh}:${sm}`)
      }
    },
    onClear() {
      this.hour = null
      this.minute = null
      this.$emit('change', null)
      this.$emit('clear')
    },
  },
}
</script>
