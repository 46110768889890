<template>
  <FlotingBox class="reservatable-frame" v-bind="$attrs">
    <div class="content" @click="onClick">
      <small>{{ frame.start | time }} ({{ frame.minutes }}分)</small>
    </div>
  </FlotingBox>
</template>

<script>
import FlotingBox from './FloatingBox'
export default {
  name: 'ReservatableFrame',
  components: {
    FlotingBox,
  },
  props: {
    frame: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClick() {
      console.log('click R frame')
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
  widows: 100%;
  border: solid 2px green;
  color: green;
  * {
    pointer-events: none;
  }
}
.reservatable-frame {
  pointer-events: none;
}
</style>
