<template>
  <v-dialog v-model="visible" max-width="600px">
    <template #activator="props">
      <slot name="activator" v-bind="props" />
    </template>
    <v-card>
      <v-card-title> 休憩時間変更 {{ shift.staffName }} </v-card-title>
      <v-card-text>
        勤務時間: {{ shift.start | time }}~{{ shift.end | time }}
        <v-form>
          <v-layout
            v-for="({ startTime, endTime }, idx) in form.breaks"
            :key="idx + 'break'"
            align-center
          >
            <span style="width: 80px;"> 休憩時間{{ idx + 1 }} </span>

            <TimeSelect
              :value="startTime"
              clearable
              style="max-width: 200px;"
              :min="minTime"
              :max="maxTime"
              @change="updateBreak(idx, { startTime: $event })"
            />
            〜
            <TimeSelect
              :value="endTime"
              :min="minTime"
              :max="maxTime"
              clearable
              style="max-width: 200px;"
              @change="updateBreak(idx, { endTime: $event })"
            />
          </v-layout>
          <v-divider />

          <template v-for="({ startTime, endTime, text }, idx) in form.blocks">
            <v-layout :key="idx" align-center>
              <span style="width: 80px;"> 予定{{ idx + 1 }} </span>

              <v-layout column justify-center>
                <v-layout align-center>
                  <TimeSelect
                    :value="startTime"
                    clearable
                    style="max-width: 200px;"
                    :min="minTime"
                    :max="maxTime"
                    @change="updateBlock(idx, { startTime: $event })"
                  />
                  〜
                  <TimeSelect
                    :value="endTime"
                    :min="minTime"
                    :max="maxTime"
                    clearable
                    style="max-width: 200px;"
                    @change="updateBlock(idx, { endTime: $event })"
                  />
                </v-layout>
                <v-text-field
                  :value="text"
                  label="理由"
                  @change="updateBlock(idx, { text: $event })"
                />
              </v-layout>
            </v-layout>
            <v-divider :key="idx + '-d'" />
          </template>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="visible = false">
          閉じる
        </v-btn>
        <v-btn color="primary" :loading="submitting" @click="submit">
          更新
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import TimeSelect from '@/components/TimeSelect'

const createEmptyBreak = type => ({
  type,
  startTime: null,
  endTime: null,
  text: '',
})

export default {
  name: 'ShiftUpdateDialog',
  components: {
    TimeSelect,
  },
  props: {
    shift: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      breaks: [createEmptyBreak('break'), createEmptyBreak('break')],
      blocks: [createEmptyBreak('block'), createEmptyBreak('block')],
    },
    submitting: false,
    visible: false,
  }),
  computed: {
    minTime() {
      return moment(this.shift.start).format('HH:mm')
    },
    maxTime() {
      return moment(this.shift.end).format('HH:mm')
    },
  },
  watch: {
    visible: {
      handler(visible) {
        let shift = this.shift
        if (visible && shift) {
          let breaks = shift.breaks
          this.form.breaks = breaks
            .filter(({ type }) => type === 'break')
            .map(x => Object.assign({}, x))
          this.form.blocks = breaks
            .filter(({ type }) => type === 'block')
            .map(x => Object.assign({}, x))
          for (let i = this.form.breaks.length; i < 2; ++i)
            this.form.breaks.push(createEmptyBreak('break'))
          for (let i = this.form.blocks.length; i < 2; ++i)
            this.form.blocks.push(createEmptyBreak('block'))
        } else {
          this.form.breaks = []
          this.form.blocks = []
        }
      },
    },
  },
  methods: {
    updateBreak(idx, value) {
      let data = Object.assign({}, this.form.breaks[idx], value)
      this.form.breaks.splice(idx, 1, data)
    },
    updateBlock(idx, value) {
      let data = Object.assign({}, this.form.blocks[idx], value)
      this.form.blocks.splice(idx, 1, data)
    },
    async submit() {
      let form = this.form
      let breaks = form.breaks
        .concat(form.blocks)
        .filter(
          ({ startTime, endTime }) => Boolean(startTime) && Boolean(endTime)
        )
      try {
        this.submitting = true
        let data = await this.$api.shifts(this.shift.id).update({ breaks })
        this.visible = false
        this.$emit('updated', data)
      } catch (err) {
        console.error(err)
      }
      this.submitting = false
    },
  },
}
</script>
