<script>
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'
import UsedTicketCard from './UsedTicketCard.vue'

export default {
  name: 'TicketFormTable',
  components: {
    InfiniteLoading,
    UsedTicketCard,
  },
  props: {
    customer: {
      type: String,
      required: true,
    },
    reserveId: {
      type: String,
      required: true,
    },
    reserveDate: {
      type: String,
      required: true,
    },
    usedTickets: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    next: null,
    items: [],
    ts: String(new Date().getTime()),
    useDialog: false,
    useTicket: null,
    useQuantity: 1,
    submitting: false,
    histDialog: false,
    histItem: null,
  }),
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id',
          sortable: false,
        },
        {
          text: '購入日',
          value: 'created',
          sortable: false,
        },
        {
          text: 'チケットグループ',
          value: 'groupName',
          sortable: false,
        },
        {
          text: '名称',
          value: 'name',
          sortable: false,
        },
        {
          text: '使用開始',
          value: 'startAt',
          sortable: false,
        },
        {
          text: '有効期限',
          value: 'expiryAt',
          sortable: false,
        },
        {
          text: '残り日数',
          value: 'restDays',
          sortable: false,
        },
        {
          text: '残りチケット数',
          value: 'restQuantity',
          sortable: false,
        },
        {
          text: '追加ユーザ',
          value: 'createdUsername',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ]
    },
    currentUserId() {
      return this.$store.getters['user']?.uuid
    },

    disableUse() {
      // NOTE: 月を跨いだら「使用」ボタンを無効にする
      const month = moment(this.reserveDate).format('YYYY-MM')
      const curMonth = moment().format('YYYY-MM')
      return month !== curMonth
    },
  },
  watch: {
    histDialog(value) {
      if (!value) this.histItem = null
    },
  },
  methods: {
    restDays(dt) {
      return moment(dt).diff(moment(), 'days')
    },
    openHist(ticket) {
      this.histItem = ticket
      this.histDialog = true
    },
    reload() {
      this.items = []
      this.next = null
      this.ts = String(new Date().getTime())
    },
    async fetchData($state) {
      try {
        this.loading = true
        let { results, next } = await (this.next
          ? this.$api.http.get(this.next)
          : this.$api.purchasedTickets().list({
              customer: this.customer,
              usable: true,
              ordering: 'expiry_at',
            }))
        this.items = this.items.concat(results)
        // this.$emit('items', this.items)
        this.next = next
        if (this.items.length) $state.loaded()
        if (!this.next) $state.complete()
      } catch (err) {
        console.error(err)
        $state.error()
      }
      this.loading = false
    },
    async openDialog(ticket) {
      this.useTicket = ticket
      this.useQuantity = 1
      this.useDialog = true
    },
    async delPurchasedTicket(ticket) {
      if (
        !confirm(
          'チケットを無効化しますか？再度の有効化は管理者権限が必要です。'
        )
      )
        return
      try {
        await this.$api
          .purchasedTickets(ticket.id)
          .partialUpdate({ isActive: false })
        this.reload()
        this.$toast.success('チケットを無効化しました。')
      } catch (err) {
        console.error(err)
        this.$toast.error('チケットの無効化に失敗しました。')
      }
    },
    async submit() {
      this.submitting = true
      try {
        await this.$api.usedTickets().create({
          ticket: this.useTicket.id,
          reserve: this.reserveId,
          usedQuantity: this.useQuantity,
          usedDate: this.reserveDate,
        })
        this.$toast.success('チケットを使用しました。')
        this.$nextTick(() => {
          this.$emit('use')
        })

        this.useDialog = false
      } catch (err) {
        console.error(err)
        this.$toast.error('チケットの使用に失敗しました。')
      }
      this.submitting = false
    },

    isStarted(item) {
      if (!item.startAt) return true
      return moment(item.startAt).isBefore(moment())
    },
  },
}
</script>

<template>
  <div>
    <v-dialog v-model="histDialog">
      <UsedTicketCard v-if="histItem" :ticket="histItem" />
    </v-dialog>
    <v-dialog v-model="useDialog" max-width="400">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>チケット使用</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model.number="useQuantity"
            type="number"
            label="使用枚数"
            :min="1"
            :max="useTicket && useTicket.restQuantity"
          >
            <template #append>
              <v-btn
                :disabled="useTicket && useTicket.restQuantity <= useQuantity"
                @click="useQuantity++"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn :disabled="useQuantity <= 1" @click="useQuantity--">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="useDialog = false">キャンセル</v-btn>
          <v-btn color="primary" :loading="submitting" @click="submit">
            使用
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :items="items"
      :headers="headers"
      :loading="loading"
      :items-per-page="items.length"
      height="400px"
      hide-default-footer
      fixed-header
    >
      <template #item.startAt="{ value }">
        {{ value | date("'YY MM/DD") }}
      </template>
      <template #item.created="{ value }">
        {{ value | date("'YY MM/DD") }}
      </template>
      <template #item.expiryAt="{ value }">
        {{ value | date("'YY MM/DD") }}
      </template>
      <template #item.restDays="{item}">
        {{ restDays(item.expiryAt) }}
      </template>
      <template #item.actions="{item}">
        <v-btn
          outlined
          :disabled="
            usedTickets.includes(item.id) ||
              !item.isActive ||
              disableUse ||
              !isStarted(item)
          "
          @click="openDialog(item)"
        >
          使用{{ usedTickets.includes(item.id) ? '中' : '' }}
        </v-btn>
        <v-btn outlined class="mx-3" @click="openHist(item)">履歴</v-btn>
        <!-- <v-btn
          color="error"
          :disabled="!item.isActive"
          @click="delPurchasedTicket(item)"
        >
          無効化
        </v-btn> -->
      </template>
      <template #body.append>
        <tr>
          <td :colspan="headers.length">
            <infinite-loading :identifier="ts" @infinite="fetchData" />
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
