
import moment from 'moment'
import { Component, Vue, Prop } from 'vue-property-decorator'

type DateAndCount = {
  date: string
  count: number
}

@Component
export default class MonthlyVisitorDialog extends Vue {
  @Prop({ type: String, required: true }) readonly shop!: string
  @Prop({ type: String, required: true }) readonly date!: string

  loading = false
  counts: DateAndCount[] = []
  selected: any[] = []

  headers = [
    { text: '', value: 'text' },
    { text: '実績', value: 'count', align: 'right' },
  ]

  get items() {
    let date2count: Record<string, number> = {}
    let total = 0
    this.counts.forEach(({ date, count }) => {
      total += count
      date2count[date] = count
    })

    let rval = []
    const end = moment(this.date).endOf('month')
    let d = moment(this.date).startOf('month')
    let subtotal = 0
    while (d.isSameOrBefore(end)) {
      let date = d.format('YYYY-MM-DD')
      let count = date2count[date] || 0
      let row = { text: d.format('DD日（dd）'), count }

      if (date < this.date) subtotal += count
      else if (date === this.date) this.selected = [row]
      rval.push(row)

      d.add(1, 'day')
    }
    return [
      { text: '昨夜時点', count: subtotal },
      { text: '月間状況', count: total },
      { text: '', count: null },
    ].concat(rval)
  }

  async fetchData() {
    try {
      this.loading = true
      this.counts = await this.$api.shops(this.shop).monthlyVisitors(this.date)
    } catch (err) {
      console.error(err)
      this.$toast.error('データの取得に失敗しました。')
    }
    this.loading = false
  }

  onInput(value: boolean) {
    if (value) this.fetchData()
    else this.counts = []
  }
}
