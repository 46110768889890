
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class TimelineBreakBox extends Vue {
  @Prop({ type: [Number, String], required: true }) readonly left!:
    | number
    | string
  @Prop({ type: [Number, String], required: true }) readonly width!:
    | number
    | string
  @Prop({ type: [Number, String], required: true }) readonly height!:
    | number
    | string
  @Prop({ type: [String], default: '' }) readonly type!: '' | 'break' | 'block'
  @Prop({ type: [String], default: '' }) readonly text!: string

  px(x: string | number) {
    return `${Number(x)}px`
  }
}
