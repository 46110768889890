<template>
  <div
    class="timeline-scale"
    :style="{
      height: px(height),
    }"
  >
    <div
      v-for="{ at, time, minute } in times"
      :key="at"
      class="scale"
      :style="style(at, minute)"
      @click="onClick(at, time, minute)"
      @mouseover="onHover(at, true)"
      @mouseleave="onHover(at, false)"
    >
      <!-- {{ minute }} -->
      <slot name="scale" :at="at" :time="time" :minute="minute" />
    </div>
    <div class="content" :style="{ height: px(height) }">
      <slot />
    </div>
  </div>
</template>

<script>
import moment from 'moment'

const px = x => `${x}px`
export default {
  name: 'TimelineScale',
  props: {
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
    unitWidth: {
      type: Number,
      required: true,
    },
    unitMinute: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    mouseOverAt: {
      type: String,
      default: null,
    },
  },
  computed: {
    times() {
      let rval = []
      let dt = moment(this.start)
      let end = moment(this.end)
      let unit = this.unitMinute
      while (dt.isSameOrBefore(end)) {
        rval.push({
          at: dt.toISOString(),
          time: dt.format('HH:mm'),
          minute: dt.format('mm'),
        })
        dt.add(unit, 'minutes')
      }
      return rval
    },
  },
  methods: {
    px,
    onClick(at, time, minute) {
      this.$emit('click-scale', { at, time, minute })
      // console.log('scale on click')
    },
    style(at, minute) {
      let w = px(this.unitWidth)
      let style = 'dotted'
      if (minute === '00') style = 'solid'
      if (minute === '30') style = 'dashed'
      return {
        height: px(this.height),
        width: w,
        'min-width': w,
        'max-width': w,
        'border-left-style': style,
        'background-color':
          at === this.mouseOverAt ? 'rgb(238, 238, 238)' : null,
      }
    },
    onHover(at, active) {
      this.$emit('active', { at, active })
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.timeline-scale {
  position: relative;
}
.scale {
  float: left;
  border-left: solid 2px rgb(221, 221, 221);
}
.content {
  position: absolute;
  width: 100%;
  clear: both;
  pointer-events: none;
  // z-index: 0;
}
</style>
