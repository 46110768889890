
import moment from 'moment'
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'

import TimelineToolbar from '@/components/Timeline/Toolbar.vue'
import TimelineScale from '@/components/Timeline/Scale.vue'
import BreakBox from '@/components/Timeline/BreakBox.vue'
import ReservatableFrame from '@/components/Timeline/ReservatableFrame.vue'
import ReservationCard from '@/components/Timeline/ReservationCard.vue'
import TickLine from '@/components/Timeline/TickLine.vue'
import ShiftHeader from '@/components/Timeline/ShiftHeader.vue'
import OverFlowCount from '@/components/Timeline/OverFlowCount.vue'
import CreateDialog from '@/components/Reservation/CreateDialog.vue'
import UpdateDialog from '@/components/Reservation/UpdateDialog.vue'

import WebSocketListener from '@/misc/websocket-listener'
import DataContainer from '@/virtual-data-table/DataContainer.vue'

import { Dictionary } from 'vue-router/types/router'

import {
  Timeline,
  ReserveFrame,
  Shift,
  WrappedReserve,
  ShiftBreak,
  ReserveAailability,
  Reservation,
  SimpleStaff,
  Menu,
  TreatmentMenu,
  ForceReservableTime,
  Overflow,
} from '@/types'

interface BreakPosition {
  left: number
  height: number
  width: number
  text?: string
  type?: string
}
interface PositionedWrappedReserve extends WrappedReserve {
  left: number
  width: number
  segmentWidths: number[]
  xPositions: number[]
}

const px = (x: number | string) => `${x}px`
const freeStaffId = '__free__'

@Component({
  components: {
    TimelineToolbar,
    TimelineScale,
    DataContainer,
    BreakBox,
    ReservatableFrame,
    ReservationCard,
    TickLine,
    ShiftHeader,
    CreateDialog,
    UpdateDialog,
    OverFlowCount,
  },
})
export default class TimelineChartView extends Vue {
  @Prop({ type: String, required: true }) readonly shopId!: string

  @Ref() readonly container!: Vue
  @Ref() readonly footer!: HTMLDivElement

  date = ''
  visibleStates: string[] = []
  timeline: null | Timeline = null
  start = ''
  end = ''
  rawShifts: Shift[] = []
  menus: Menu[] = []
  treatments: TreatmentMenu[] = []
  availabilities: ReserveAailability[] = []
  frames: ReserveFrame[] = []
  forceReservables: ForceReservableTime[] = []
  reservationFrames: WrappedReserve[] = []
  confirmationRequired: Reservation[] = []
  containerHeight = 800
  dataLoading = false
  createDialog: Dictionary<unknown> = {
    visible: false,
    staff: null,
    time: null,
    date: null,
    menu: null,
  }
  updateDialog: { visible: boolean; reserve: Reservation | null } = {
    visible: false,
    reserve: null,
  }
  allStaffs: SimpleStaff[] = []
  _ws: WebSocketListener | null = null
  highlightAt: null | string = null
  // shiftIdToWorking: Map<string, boolean> = new Map() // 入室中かどうか
  title: null | string = null
  showFrame = true
  reserveConfirmDialog = false
  // computed
  get ready() {
    return Boolean(this.start && this.end)
  }
  get scalpCares() {
    return this.timeline?.scalpCares
  }
  get fufumore() {
    return this.timeline?.fufumore || false
  }
  get shop() {
    return this.timeline?.shop
  }
  get minTime() {
    return moment(this.start).format('HH:mm')
  }
  get maxTime() {
    return moment(this.end).format('HH:mm')
  }
  get staffs() {
    return this.rawShifts
      .filter(x => !x.isDummy)
      .map(x => ({
        id: x.staff,
        name: x.staffName,
      }))
  }
  get enableForceReservable() {
    if (this.fufumore) return false
    return true
  }
  get timeToOverflow() {
    let rval = {} as Record<string, Overflow>
    if (this.timeline?.overflows) {
      this.timeline.overflows.forEach(x => {
        rval[x.time] = x
      })
    }
    return rval
  }
  get totalOverFlow() {
    return this.timeline?.overflows
      .map(x => x.overflowCount)
      .filter(x => x > 0)
      .reduce((a, b) => a + b, 0)
  }

  get filteredWrappedReserve() {
    let visibleCancel = this.visibleStates.includes('5')
    let visibleNoShow = this.visibleStates.includes('6')
    let visibleDeleted = this.visibleStates.includes('-1')
    return this.reservationFrames.filter(x => {
      let r = x.reservation
      if (!visibleDeleted && r.isRemoved) return false
      if (!visibleCancel && r.state === 5) return false
      if (!visibleNoShow && r.state === 6) return false
      return true
    })
  }
  get hasFreeReservation() {
    return this.filteredWrappedReserve.some(x => !x.staffId)
  }
  get shifts() {
    let shifts = this.rawShifts
    if (this.hasFreeReservation) {
      let dummyShift: Shift = {
        id: '_free_',
        isDummy: true,
        staff: freeStaffId,
        staffName: 'フリー',
        start: this.start,
        end: this.end,
        breaks: [],

        date: this.date,
        shop: this.shopId,
        isProbation: false,
        isVacation: false,
        isPaidVacation: false,
      }
      return [dummyShift].concat(shifts)
    }
    return shifts
  }
  get atToAvailability() {
    let rval: Dictionary<ReserveAailability> = {}
    this.availabilities.forEach(x => {
      rval[moment(x.at).toISOString()] = x
    })
    return rval
  }
  get atToForceReservableTime() {
    let rval: Record<string, ForceReservableTime> = {}
    this.forceReservables.forEach(x => {
      rval[moment(x.startAt).toISOString()] = x
    })
    return rval
  }
  get leftWidth() {
    return 150
  }
  get unitWidth() {
    if (this.fufumore) return 50
    return 30
  }
  get unitMinute() {
    return this.timeline?.unitMinutes || 10
  }
  get timeWidth() {
    return (
      (moment(this.end).diff(this.start, 'minutes') / this.unitMinute) *
      (this.unitWidth + 1)
    )
  }
  get frameTitleHeight() {
    // 予約枠のタイトルの高さ
    return 30
  }
  get rowHeight() {
    return 80
  }
  get dataHeight() {
    return this.shifts.map(this.getShiftHeight).reduce((a, b) => a + b, 0)
  }
  get dyestuffs() {
    let rval = this.timeline?.dyestuffs
    if (!rval) return []
    // rval.sort((a, b) => a.color.localeCompare(b.color))
    return rval
  }
  get staffIdToWrappedReserves() {
    let rval = new Map() as Map<string, PositionedWrappedReserve[][]>
    if (this.shifts) {
      this.shifts.forEach(shift => {
        rval.set(shift.staff, this.getWrappedReservesByStaff(shift.staff))
      })
    }
    return rval
  }
  get positionedFrames() {
    // NOTE: heightはスタッフ毎に違うのでここでは計算しない
    let start = moment(this.start)
    let wScale = this.unitWidth / this.unitMinute
    let rval = this.frames.map(frame =>
      Object.assign(
        {
          left: moment(frame.start).diff(start, 'minutes') * wScale,
          width: frame.minutes * wScale,
        },
        frame
      )
    )
    rval.sort((a, b) => a.start.localeCompare(b.start))
    return rval
  }
  get positionedWrappedReserves() {
    // NOTE: 空フレームと予約フレームを一緒に返すのを注意
    let start = moment(this.start)
    let unitWidth = this.unitWidth / this.unitMinute
    return this.filteredWrappedReserve.map(frame => {
      let left = moment(frame.start).diff(start, 'minutes') * unitWidth
      let widths = frame.segmentMinutes.map(m => m * unitWidth)
      let xPositions = [
        left,
        left + widths[0],
        left + widths[0] + widths[1],
        left + widths[0] + widths[1] + widths[2],
      ]
      return Object.assign(
        {
          left,
          width: widths.reduce((a, b) => a + b, 0),
          segmentWidths: widths,
          xPositions,
        },
        frame
      )
    })
  }

  @Watch('date', { immediate: true })
  onRouteChanged(date: string) {
    if (date) this.fetchData()
  }

  // lifecycle
  created() {
    this._ws = new WebSocketListener(
      `${process.env.VUE_APP_WS_ENDPOINT}timeline/${this.shopId}/`
    )
      .on('open', () => {
        this.fetchData()
      })
      .on('data', () => {
        console.log('?ws update')
        this.fetchData()
      })
  }
  mounted() {
    // NOTE: レンダリング順の関係でslot=topが縦に長い場合にfooterが表示されないことおがある
    this.$nextTick(() => {
      this.fixHeight()
    })
  }
  beforeDestroy() {
    if (this._ws) {
      this._ws.destroy()
      this._ws = null
    }
  }

  metaInfo() {
    return {
      title: this.title,
    }
  }

  // methods

  px = px

  openCreateDialog(
    { time }: { time: string; at: string; minute: number },
    shift: Shift
  ) {
    // NOTE: timeline上をクリックする場合
    this.createDialog.time = time
    this.createDialog.shop = shift.shop
    this.createDialog.staff = shift.isDummy ? null : shift.staff
    this.createDialog.visible = true
  }
  openCreateDialog2(val: { date: string; time: string; menu: string }) {
    // NOTE: TimeListDialogから開く場合
    this.createDialog.time = val.time
    this.createDialog.date = val.date
    this.createDialog.menu = val.menu
    this.createDialog.staff = null
    this.createDialog.shop = this.shopId
    this.createDialog.visible = true
  }
  onOpenDialogChanged(visible: boolean) {
    if (visible) return
    // NOTE: 終了処理
    let dialog = this.createDialog
    dialog.date = null
    dialog.time = null
    dialog.staff = null
    dialog.menu = null
  }

  openUpdateDialog(reserve: Reservation) {
    this.updateDialog.visible = true
    this.updateDialog.reserve = reserve
  }
  onUpdateDialog(value: boolean) {
    if (!value) this.updateDialog.reserve = null
  }

  fixHeight() {
    const rect = this.container.$el.getBoundingClientRect()
    if (rect) {
      const footRect = this.footer.getBoundingClientRect()
      // console.log(window.innerHeight, rect, footRect)
      const height = window.innerHeight - rect.top - footRect.height
      this.containerHeight = Math.min(Math.max(height, 500), window.innerHeight)
    }
  }
  async fetchData() {
    if (!this.date) return
    this.dataLoading = true
    try {
      let data = await this.$api
        .timelines({
          date: this.date,
          shop: this.shopId,
        })
        .get()
      this.timeline = data
      let {
        shifts,
        start,
        end,
        frames,
        reservationFrames,
        availabilities,
        menus,
        // menuOptions,
        treatments,
        staffs,
        forceReservables,
        confirmationRequired,
      } = data
      this.start = start
      this.end = end

      this.rawShifts = shifts
      this.frames = frames
      this.allStaffs = staffs

      this.availabilities = availabilities
      this.menus = menus
      //this.menuOptions = menuOptions
      this.treatments = treatments
      this.forceReservables = forceReservables
      this.confirmationRequired = confirmationRequired || []

      // NOTE: 割当られているスタッフがシフトにいない場合にnullをセットしておく
      let staffIds = new Set(shifts.map(x => x.staff))
      // shifts.forEach(x => {
      //   staffIds[x.staff] = true
      // })
      reservationFrames.forEach(x => {
        if (!staffIds.has(x.staffId as string)) {
          x.staffId = null
          x.staffName = null
        }
        if (!staffIds.has(x.shampooStaffId as string)) {
          x.shampooStaffId = null
          x.shampooStaffName = null
        }
      })
      reservationFrames.sort((a, b) => a.start.localeCompare(b.start))
      this.reservationFrames = reservationFrames

      this.title = `cip ${this.timeline.shop.name}`
      if (this.updateDialog.reserve) {
        const id = this.updateDialog.reserve.id
        const reserve = reservationFrames.find(x => x.reservation.id === id)
          ?.reservation
        if (reserve) {
          this.updateDialog.reserve = reserve
        } else {
          this.updateDialog.reserve = null
          this.updateDialog.visible = false
          this.$toast.info('予約が変更されました。')
        }
      }
    } catch (err) {
      console.error(err)
      this.$toast.error('データの取得に失敗しました。')
    }
    this.dataLoading = false
  }
  getFrames(staffId: string) {
    // 80, 90分の予約枠
    return this.positionedFrames.filter(frame => frame.staffId === staffId)
  }
  getWrappedReservesByStaff(staffId: string | null) {
    // TODO: シャンプーのみ割当でも返すようにする
    let rval: PositionedWrappedReserve[][] = [[]]
    if (staffId === freeStaffId) staffId = null
    let frames = this.positionedWrappedReserves.filter(
      frame => frame.staffId === staffId
    )
    frames.sort((a, b) => a.start.localeCompare(b.start))
    frames.forEach(frame => {
      let len = rval.length
      for (let i = 0; i < len; ++i) {
        let arr = rval[i]
        if (!this._hasOverlapFrame(arr, frame)) {
          arr.push(frame)
          return
        }
      }
      rval.push([frame])
    })
    return rval
  }
  getShiftHeight(shift: Shift) {
    let arr = this.staffIdToWrappedReserves.get(shift.staff)
    let h = this.rowHeight
    if (!arr) return h
    return h * arr.length + this.frameTitleHeight
  }
  _hasOverlapFrame(
    frames: PositionedWrappedReserve[],
    frame: PositionedWrappedReserve
  ) {
    return frames.some(({ xPositions }) => {
      const L = xPositions[0]
      const R = xPositions[3]
      const s = frame.xPositions[0]
      const e = frame.xPositions[3]
      return (
        (L < s && s < R) ||
        (L < e && e < R) ||
        (s < L && L < e) ||
        (s < R && R < e) ||
        (L === s && R === e)
      )
    })
  }
  getBreaks(shift: Shift): BreakPosition[] {
    let start = moment(this.start)
    let unitWidth = this.unitWidth / this.unitMinute
    let height = this.getShiftHeight(shift)
    let outOfWorking: BreakPosition[] = []
    if (start.isBefore(shift.start)) {
      outOfWorking.push({
        left: 0,
        width: moment(shift.start).diff(start, 'minutes') * unitWidth,
        height,
      })
    }
    if (moment(shift.end).isBefore(this.end)) {
      outOfWorking.push({
        left: moment(shift.end).diff(this.start, 'minutes') * unitWidth,
        width: moment(this.end).diff(shift.end, 'minutes') * unitWidth,
        height,
      })
    }
    let rval: BreakPosition[] = shift.breaks.map((data: ShiftBreak) => {
      return Object.assign(
        {
          left: moment(data.start).diff(start, 'minutes') * unitWidth,
          width: moment(data.end).diff(data.start, 'minutes') * unitWidth,
          height,
        },
        data
      )
    })
    return rval.concat(outOfWorking)
  }
  onShiftUpdated() {
    this.fetchData()
  }

  onMouseOverScaleChanged({ at, active }: { at: string; active: boolean }) {
    if (active) this.highlightAt = at
    else if (this.highlightAt === at) this.highlightAt = null
  }

  isOverResource(at: string) {
    let av: undefined | ReserveAailability = this.atToAvailability[at]
    if (!av) return false
    return av.sinkCount < 0 || av.staffCount < 0
  }

  async toggleForceReservable(at: string, obj?: ForceReservableTime) {
    try {
      if (obj) await this.$api.forceReservableTimes(obj.id).delete()
      else
        await this.$api
          .forceReservableTimes()
          .create({ shop: this.shop?.id, startAt: at })
    } catch (err) {
      console.error(err)
    }
  }
}
