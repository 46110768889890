<script>
import moment from 'moment'
import TicketFormTable from './TicketFormTable.vue'
import TicketFormTable2 from './TicketFormTable2.vue'

export default {
  name: 'TicketForm',
  components: {
    TicketFormTable,
    TicketFormTable2,
  },
  props: {
    customer: {
      type: String,
      required: true,
    },
    reserveId: {
      type: String,
      required: true,
    },
    reserveDate: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    purchaseDialog: false,
    purchaseDialogLoading: false,
    purchaseQuantityDialog: false,
    purchaseQuantity: 1,
    purchaseTicket: null,
    adding: false,
    tickets: [],
    usedTickets: [],
    tab: 0,
  }),
  computed: {
    disableDelete() {
      // NOTE: 月を跨いだら「使用」ボタンを無効にする
      const month = moment(this.reserveDate).format('YYYY-MM')
      const curMonth = moment().format('YYYY-MM')
      return month !== curMonth
    },
  },
  watch: {
    purchaseDialog(val) {
      if (val) this.fetchTickets()
    },
  },
  mounted() {
    this.fetchUsedTickets()
  },
  methods: {
    openQuantity(ticket) {
      this.purchaseTicket = ticket
      this.purchaseQuantity = 1
      this.purchaseQuantityDialog = true
    },
    async fetchTickets() {
      try {
        this.purchaseDialogLoading = true
        const { results } = await this.$api.tickets().list()
        this.tickets = results
      } catch (err) {
        this.$toast.error('チケットの取得に失敗しました。')
      }
      this.purchaseDialogLoading = false
    },

    async purchase() {
      try {
        this.adding = true
        await this.$api.tickets().add({
          ticket: this.purchaseTicket.id,
          quantity: this.purchaseQuantity,
          customer: this.customer,
        })
        this.purchaseQuantityDialog = false
        this.purchaseDialog = false
        this.$refs.tbl.reload()
      } catch (err) {
        this.$toast.error('チケットの追加に失敗しました。')
      }
      this.adding = false
    },

    async fetchUsedTickets() {
      const { results } = await this.$api
        .usedTickets()
        .list({ reserve: this.reserveId })
      this.usedTickets = results
    },
    async delUsedTicket(ticket) {
      try {
        await this.$api.usedTickets(ticket.id).delete()
        this.fetchUsedTickets()
        this.$refs.tbl.reload()
      } catch (err) {
        console.error(err)
      }
    },
  },
}
</script>

<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title> チケット </v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn @click="purchaseDialog = true">
          購入
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-dialog v-model="purchaseQuantityDialog" max-width="400px">
      <v-card>
        <v-card-title>
          購入数量
        </v-card-title>
        <v-card-text>
          {{ purchaseTicket && purchaseTicket.name }}
          <v-text-field
            v-model.number="purchaseQuantity"
            min="1"
            label="数量"
            type="numbeer"
            disabled
          >
            <!-- <template #append>
              <v-btn @click="purchaseQuantity++">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-btn
                :disabled="purchaseQuantity <= 1"
                @click="purchaseQuantity--"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </template> -->
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined @click="purchaseQuantityDialog = false">
            キャンセル
          </v-btn>
          <v-btn color="primary" :loading="adding" @click="purchase">
            購入
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="purchaseDialog">
      <v-card :loading="purchaseDialogLoading">
        <v-card-title>チケット購入</v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-for="ticket in tickets" :key="ticket.id">
              <v-btn outlined x-large @click="openQuantity(ticket)">
                {{ ticket.name }}
                {{ Number(ticket.price).toLocaleString() }}円
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined @click="purchaseDialog = false">閉じる</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <template v-if="usedTickets.length > 0">
      <v-subheader>
        使用中
      </v-subheader>
      <v-data-table
        :items="usedTickets"
        :headers="[
          { text: 'チケットID', value: 'ticket' },
          { text: 'チケットグループ', value: 'groupName' },
          { text: '名称', value: 'name' },
          { text: '残り枚数', value: 'restQuantity' },
          { text: '使用枚数', value: 'usedQuantity' },
          { text: '', value: 'actions' },
        ]"
        :items-per-page="usedTickets.length"
        hide-default-footer
      >
        <template #item.ticket="{value}">
          {{ value || '削除済' }}
        </template>
        <template #item.usedQuantity="{value}">
          <b class="info pa-2 white--text">{{ value }}</b>
        </template>
        <template #item.actions="{item}">
          <v-btn
            color="error"
            :disabled="disableDelete"
            @click="delUsedTicket(item)"
            >取り消し</v-btn
          >
        </template>
      </v-data-table>
    </template>

    <v-tabs v-model="tab">
      <v-tab>使用可能</v-tab>
      <v-tab>使用済</v-tab>
    </v-tabs>
    <v-window v-model="tab">
      <v-window-item>
        <TicketFormTable
          ref="tbl"
          :customer="customer"
          :reserve-id="reserveId"
          :reserve-date="reserveDate"
          :used-tickets="usedTickets.map(x => x.ticket)"
          @use="fetchUsedTickets"
        />
      </v-window-item>
      <v-window-item>
        <TicketFormTable2
          :customer="customer"
          :reserve-id="reserveId"
          :used-tickets="usedTickets.map(x => x.ticket)"
        />
      </v-window-item>
    </v-window>
  </div>
</template>
